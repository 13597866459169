<template>
    <div class="">

        <div class="__side_nav">
                                <button class="btn"
                                    :class="[$route.path == '/admin/users/individual' ? '__side_item_active' : '']"
                                    @click="$router.replace('/admin/users/individual').catch(err => { })">
                                    Individual
                                </button>
                                <button class="btn"
                                    :class="[$route.path == '/admin/users/sme' ? '__side_item_active' : '']"
                                    @click="$router.replace('/admin/users/sme').catch(err => { })">
                                    SME
                                </button>
                                <button class="btn"
                                    :class="[$route.path == '/admin/users/corporate' ? '__side_item_active' : '']"
                                    @click="$router.replace('/admin/users/corporate').catch(err => { })">
                                    Corporate
                                </button>
                               
                            </div>
        <MainUserComponent :parent="parent">
        </MainUserComponent>
    </div>
</template>

<script>
import store from '@/state/store.js'
import MainUserComponent from './mainsUsersComponent'
import appConfig from '@/app.config'
import userSegments from './userSegments.vue'

export default {
    page: {
        title: 'SME Users',
        meta: [{ name: 'description', content: appConfig.description }],
    },
    name: 'tagged-users',
    components: {
        MainUserComponent,
        userSegments
    },
    data() {
        return {
            parent: {
                name: 'sme',
                storeDataName: 'smeUsers',
                dispatchName: 'admin/users/getSmeUsers',
                searchDispatchName: 'admin/users/searchSmeUsers',
            },
        }
    },
    methods: {
    },
    computed: {
    },
    mounted() {
        // store.dispatch('admin/users/getSmeUsers').then((data) => {
           
        // })
    }
}
</script>

<style lang="scss" scoped>
#enter__account {
    font-family: 'Graphik Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 13px;
    color: #F7921C;
    outline: none;
    border: none;
    background-color: transparent;
}

#__edit {
    outline: none;
    border: none;
    background: transparent;
    margin-left: .5em;
    width: 10px !important;
    padding: 0px;
}
</style>